.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-column-title {
  width: 100%;
}
body {
  overflow: hidden;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  /* padding: 1rem 1rem; */
  border: 1px solid #4a6ba138;
  font-weight: 700;
  color: #4a6ba1;
  background: #edebfa;
  transition: box-shadow 0.2s;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #4a6ba1;
  margin-left: 0.5rem;
}
.p-inputtext {
  padding: 0;
  padding-left: 10px;
  height: 40px;
}
#search2 .p-inputtext {
  padding: 0;
  padding-left: 10px;
  height: 30px;
}
#search3 .p-inputtext {
  padding: 0;
  padding-left: 10px;
  height: 30px;
  max-width: 200px;
}
#search3 .p-autocomplete-items-wrapper {
  max-width: 200px;
}
#calendar-12h .p-inputtext {
  padding: 0;
  padding-left: 10px;
  height: 30px;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #4a6ba138;
  /* border-width: 0 0 1px 0; */
  font-weight: 700;
  color: #4a6ba1;
  background: #edebfa;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #4a6ba1;
  color: #4a6ba1;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #4a6ba1 !important;
}
.p-tabview .p-tabview-panels {
  padding: 0.5rem;
  background-color: unset !important;
}
.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.3rem 0.3rem;
}
#HOMETABLE.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  /* padding: 1rem 1rem; */
  border: 1px solid #4a6ba138;
  font-weight: 700;
  background: #fff2e2;
  color: #cc8925;
  transition: box-shadow 0.2s;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  /* justify-content: center; */
  display: flex;
  align-items: center;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  /* justify-content: center; */
  display: flex;
  align-items: center;
}
.highlighted-row {
  background-color: #ffe6e6;
}
.p-inputgroup-addon {
  min-width: 2rem;
}
.Select-menu-outer{
  z-index: 999 !important ;
}
